<template>
  <div class="TranslateBox">
    <van-action-sheet v-model="show" title=" ">
      <div class="contentBox">
        <TranslateCoin ref="translateCoin"></TranslateCoin>
        <Space :height="44"></Space>
        <van-button block type="primary" class="radius10" @click="translateCoin" :loading="$store.state.submitLoading">
          划转
        </van-button>
        <Space :height="49"></Space>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  props: ['detail', 'showDigSheet'],
  data() {
    return {
      show: false,
    };
  },
  // created() {},
  // mounted () {},
  methods: {
    init(res) {
      this.show = res;
      this.$nextTick(() => {
        this.$refs.translateCoin.init();
      });
    },
    translateCoin() {
      this.$refs.translateCoin.submit(() => {
        this.showDigSheet();
      });
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.contentBox {
  /deep/ .inputBox {
    padding: 0;
    .titleBox,
    .balanceBox {
      margin: 0 30px;
    }
  }
}
</style>
