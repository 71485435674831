<template>
  <div class="DigSheetBox">
    <van-action-sheet v-model="show" title=" ">
      <div class="contentBox">
        <van-field
          type="number"
          :placeholder="`最小锁仓${detail.min} ${detail.miningCoinName}`"
          class="bg radius10"
          :formatter="res => $toFixed(res, coinDetail.xiaoShuDian)"
          v-model="input"
        >
          <template #button>
            <div class="line van-hairline--left"></div>
            <van-button size="small" type="info" plain @click="onAll">全部</van-button>
          </template>
        </van-field>
        <Space :height="31"></Space>
        <div class="balanceBox">
          <div>可用：{{ $toFixed(coinDetail.available, coinDetail.xiaoShuDian, true) }} {{ coinDetail.coinName }}</div>
          <div @click="showTransform">划转</div>
        </div>
        <Space :height="31"></Space>
        <div class="tipsBox">
          预计收益：{{ $toFixed(expectedEarnings, coinDetail.xiaoShuDian, true) }} {{ detail.closeCoinName }}
        </div>
        <Space :height="28"></Space>
        <van-button block type="primary" class="radius10" @click="submit" :loading="$store.state.submitLoading"
          >立即锁仓</van-button
        >
        <Space :height="49"></Space>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  props: ['showTranslate', 'detail'],
  data() {
    return {
      input: '',
      show: false,
      coinDetail: {
        available: '',
        balance: '',
        coinId: '',
        coinName: '',
        frozen: '',
        isDeposit: '',
        isWithdraw: '',
        userId: '',
        walletAccountId: '',
        xiaoShuDian: '',
      },
    };
  },
  // created() {},
  // mounted () {},
  methods: {
    onAll() {
      this.input = this.coinDetail.available;
    },
    getUserAssets() {
      this.$http('post', '/v1/user/userAssetsInfo', {
        coinId: this.detail.coinId,
        walletAccountId: this.detail.walletAccountId,
      }).then(res => {
        this.coinDetail = res.data;
      });
    },
    init(res) {
      this.show = res;
      if (res) {
        this.input = '';
        this.getUserAssets();
      }
    },
    showTransform() {
      this.showTranslate();
    },
    submit: _.debounce(function() {
      if (this.input < this.detail.min) {
        this.$toast(`最小数量为${this.detail.min}${this.coinDetail.coinName}`);
        return;
      }
      if (this.input > this.detail.max) {
        this.$toast(`最大数量为${this.detail.max}`);
        return;
      }
      if (this.input > this.coinDetail.available) {
        this.$toast('币种可用余额不足');
        return;
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/locked/lockedUserBuy', {
        amount: this.input,
        lockedActivityId: this.detail.activeId,
      })
        .then(res => {
          this.$toast('锁仓成功');
          this.init(false);
          this.$router.push('/locked/order');
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
  },
  // watch: {},
  computed: {
    expectedEarnings() {
      // 预计收益
      let val = this.$accMul(this.$accMul(this.input, this.detail.apr) / 365, this.detail.day);
      val = this.$toFixed(val, this.coinDetail.xiaoShuDian);
      return Number(val);
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.line {
  width: 1px;
  height: 30px;
  margin-right: 30px;
}
.balanceBox {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  div:last-child {
    color: @text-color2;
    text-decoration: underline;
  }
}
.tipsBox {
  text-align: center;
  font-size: 24px;
  color: @title-color;
}
</style>
