<template>
  <div class="digBox">
    <NavBar title="立即锁仓"></NavBar>
    <div class="contentBox">
      <Space :height="30"></Space>
      <div class="listBox">
        <div class="titleBox">
          <img :src="detail.icon" alt="" class="icon" />
          <div class="title">{{ detail.title }}</div>
        </div>
        <div class="bottomBox">
          <div class="textBox">
            <div>
              <div>{{ detail.radio }}</div>
              <div>预计年化</div>
            </div>
            <div>
              <div>{{ detail.time }}</div>
              <div>锁仓周期</div>
            </div>
          </div>
          <div class="spaceBox">
            <div class="box">
              <div>锁仓时间</div>
              <div>{{ detail.activeTime }}</div>
            </div>
            <div class="box">
              <div>生效时间</div>
              <div>{{ detail.startTime }}</div>
            </div>
            <div class="box">
              <div>赎回时间</div>
              <div>{{ detail.overTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <Space :height="30"></Space>
      <div class="ruleBox">
        <div class="title">锁仓规则</div>
        <div class="textBox">
          <div>
            ·用户参与锁仓后，次日零点开始计算锁仓收益，锁仓周期结束T+{{ detail.cycle }}可赎回参与锁仓的{{
              detail.miningCoinName
            }}。
          </div>
          <div>·锁仓起投额度：{{ detail.min }} {{ detail.miningCoinName }}。</div>
          <div>
            ·锁仓奖励结算以{{ detail.closeCoinName }}进行结算，（本金*期化收益）/ 365*{{ detail.time }}.为每次结算收益。
          </div>
          <div>·锁仓结束日本金T+{{ detail.cycle }}返还到账户。</div>
          <div>·锁仓期间用户锁仓的{{ detail.miningCoinName }}不可取出。</div>
        </div>
      </div>
      <Space :height="49"></Space>
      <van-button block type="primary" class="radius" @click="dig">立即锁仓</van-button>
      <Space :height="56"></Space>
    </div>
    <DigSheet ref="DigSheet" :detail="detail" :show-translate="showTranslate"></DigSheet>
    <Translate ref="Translate" :detail="detail" :show-dig-sheet="showDigSheet"></Translate>
  </div>
</template>
<script>
import DigSheet from './component/DigSheet.vue';
import Translate from './component/Translate.vue';
export default {
  name: '',
  // mixins: [],
  components: { DigSheet, Translate },
  // props: [],
  data() {
    return {
      detail: {},
    };
  },
  created() {
    this.getDetail();
    this.$store.dispatch('getUserInfo');
  },
  mounted() {
    this.$store.commit('setBodyColor');
  },
  methods: {
    getDetail() {
      this.$http('get', '/v1/locked/LockedActivityDetails', {
        id: this.$route.query.id,
      }).then(res => {
        this.detail = {
          icon: require('@/assets/images/locked/1.png'),
          title: res.data.title,
          radio: `${this.$accMul(res.data.apr, 100)}%`,
          apr: res.data.apr,
          time: `${res.data.day}天`,
          day: res.data.day,
          activeTime: this.$dayjs().format('YYYY/MM/DD'),
          startTime: this.$dayjs()
            .add(1, 'day')
            .format('YYYY/MM/DD'),
          overTime: this.$dayjs()
            .add(res.data.day, 'day')
            .format('YYYY/MM/DD'),
          min: res.data.min,
          max: res.data.max,
          miningCoinName: res.data.miningCoinName,
          closeCoinName: res.data.closeCoinName,
          cycle: res.data.type,
          coinId: res.data.miningCoinId,
          walletAccountId: res.data.walletAccountId,
          activeId: this.$route.query.id,
          isIdentify: res.data.isIdentify,
        };
      });
    },
    dig() {
      if (this.detail.isIdentify === 1) {
        if (this.$store.state.userInfo.status !== 2) {
          this.$toast('参与此活动需要实名认证');
          return;
        }
      }
      this.$refs.DigSheet.init(true);
    },
    showTranslate() {
      this.$refs.Translate.init(true);
      this.$refs.DigSheet.init(false);
    },
    showDigSheet() {
      this.$refs.Translate.init(false);
      this.$refs.DigSheet.init(true);
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.listBox {
  .radius;
  background: @white;
  .titleBox {
    height: 106px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @line-color1;
    padding: 0 26px;
    .icon {
      width: 56px;
      height: 56px;
      margin-right: 16px;
    }
    .title {
      font-size: 32px;
      color: @text-color1;
    }
  }
  .bottomBox {
    > .textBox {
      height: 164px;
      padding: 0 149px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid @line-color1;
      > div {
        text-align: center;
        &:first-child {
          div {
            &:first-child {
              color: @text-color3;
            }
          }
        }
        div {
          &:first-child {
            font-size: 44px;
            font-weight: bold;
            color: @title-color;
            text-align: center;
          }
          &:last-child {
            color: @intro-color;
            font-size: 24px;
            margin-top: 13px;
          }
        }
      }
    }
    .spaceBox {
      padding: 10px 32px 30px;
      .box {
        padding-top: 22px;
        display: flex;
        justify-content: space-between;
        font-size: 26px;
        color: @text-color1;
      }
      .state {
        color: @button-black-color;
      }
    }
  }
}

.ruleBox {
  .radius;
  background: @white;
  .title {
    height: 106px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @line-color1;
    padding: 0 26px;
    font-size: 32px;
    color: @text-color1;
  }
  .textBox {
    padding: 21px 30px 30px;
    div {
      @gap: 8px;
      color: @intro-color;
      line-height: 2.5;
      text-indent: -@gap;
      margin-left: @gap;
      font-size: 24px;
    }
  }
}
</style>
